<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'

export default {
  name: 'app',
  data: () => ({
    sessionToken: null
  }),
  mounted() {
    this.sessionToken = uuid.v1()
    sessionStorage.setItem('sessionToken', this.sessionToken)
  }
}
</script>

<style lang="sass">
*
  box-sizing: border-box!important
body
  overflow-y: scroll
  overflow-x: hidden
  background: $grey-fill
  margin: 0
  font-family: $main-font
  color: $main-text
  @extend %16
  &.modal-open
    overflow-y: hidden
  h1
    font-weight: 700
    line-height: 130%
    +media((font-size: (0: rem(24), 768: rem(34))))
  h2
    margin: 0
    margin-bottom: rem(30)
    font-weight: 700
    +media((font-size: (0: rem(20), 768: rem(24))))
  h3
    font-weight: 500
    +media((font-size: (0: rem(20), 768: rem(24))))
  h4
    +media((font-size: (0: rem(18), 768: rem(20))))
  h5
    +media((font-size: (0: rem(16), 768: rem(18))))
  h6
    font-weight: 500
    +media((font-size: (0: rem(14), 768: rem(16))))
  h1, h2, h3, h4, h5, h6
    text-align: left
  ul
    padding: 0
    margin: 0
    list-style: none
  p
    margin-top: 0
  a
    text-decoration: none
  .img-wrapper
    background: $border-color
    border-radius: $radius
    overflow: hidden
  input[type="radio"]
    position: absolute
    left: 0
    top: 0
    height: 0
    width: 0
    display: none
    opacity: 0
    pointer-events: none

  *::-webkit-scrollbar
    width: rem(5)
    height: rem(5)
  *::-webkit-scrollbar-track
    box-shadow: inset 0 0 6px $placeholder
  *::-webkit-scrollbar-thumb
    background-color: $main-color
    border-radius: rem(2.5)

  *::selection
    background: $selection-color
#app
  display: flex
  flex-flow: column nowrap
  align-items: center
  text-align: center
  font-family: $main-font
  .container
    max-width: rem(1230)
    padding: 0 rem(15)
    margin: 0 auto
</style>
