export const sendStatistic = label => window.dataLayer.push({ 'event': label })

export const calc = 'calc-sound'

export const statisticLabels = {
  steps: {
    step1: `${calc}-step1`,
    step2: `${calc}-step2`,
    step3: `${calc}-step3`,
    result: `${calc}-final`
  }
}
