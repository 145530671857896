import { getContent } from '@/api'
import { locale } from '@/plugins/i18n'

let pathname = window.location.pathname.split('/').filter(i => i !== '')[0]
if (pathname === 'result') {
  window.location.href = `/ru/ru/result/${window.location.search}`
}

export let region = pathname ?? 'ru'

export function makeInitialRequest() {
  return getContent(locale, region).then((data) => {
    return Promise.resolve({
      content: data.data
    })
  })
}
