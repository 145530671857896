import Vue from 'vue'
import Router from 'vue-router'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'
import { fallbackLocale } from '@/plugins/i18n'

export function initRouter(plugin) {
  const { i18n } = plugin

  Vue.use(Router)

  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        redirect: `/${i18n.locale}`
      },
      {
        path: '/:lang',
        component: {
          render(h) {
            return h('router-view')
          }
        },
        redirect: () => `/${i18n.locale}/wizard/0`,
        beforeEnter(to, from, next) {
          to.path.split('/').reverse()[0] === ''
            ? next({ path: `/${i18n.locale}` })
            : next()
        },
        children: [
          {
            path: 'wizard/:id',
            name: 'Wizard',
            component: () => import('@/views/Wizard.vue')
          },
          {
            path: 'result',
            name: 'Result',
            component: () => import('@/views/Result.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.result)
              next()
            }
          },
          {
            path: '404',
            name: '404',
            component: () => import('@/views/404.vue')
          },
          {
            path: '*',
            name: 'MissingPage',
            component: () => import('@/views/404.vue')
          }
        ]
      }
    ],
    scrollBehavior() {
      return { x: 0, y: 0 }
    }
  })

  router.beforeEach((to, from, next) => {
    const isLanguageSupported = JSON.parse(localStorage.getItem('languages'))
      .includes(to.params.lang)

    if (!isLanguageSupported) {
      i18n.locale = fallbackLocale
    }

    !to.path.includes(`/${i18n.locale}`)
      ? next({ path: `/${i18n.locale}${to.path}` })
      : next()
  })

  return Promise.resolve({
    ...plugin,
    router
  })
}
