import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from './translation'

export const fallbackLocale = 'ru'
export const locale = window.location.pathname.split('/').filter(i => i !== '')[0] ?? fallbackLocale

export function initI18n(data) {
  const languages = [fallbackLocale]

  localStorage.setItem('languages', JSON.stringify(languages))

  const isLanguageSupport = languages ? languages.includes(locale) : false

  Vue.use(VueI18n)

  return Promise.resolve({
    i18n: new VueI18n({
      locale,
      fallbackLocale,
      messages: {
        [isLanguageSupport ? locale : fallbackLocale]: messages
      }
    })
  })
}
