import Vue from 'vue'
import { initI18n } from '@/plugins/i18n'
import { makeInitialRequest } from '@/utils/makeInitialRequest'
import UnsupportBrowsers from './UnsupportBrowsers.vue'
import App from './App.vue'
import { initRouter } from '@/router'
import { initStore } from '@/store'
import PrimeVue from 'primevue/config'
import Dropdown from 'primevue/dropdown'
import VueLoading from 'vue-loading-overlay'
import 'primevue/resources/primevue.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'

export const hasSavedSessionInfo = sessionStorage.getItem('sectors')

Vue.use(PrimeVue)
Vue.component('Dropdown', Dropdown)
Vue.config.productionTip = false
Vue.use(VueLoading)
Vue.component('loading', VueLoading)

// Internet Explorer 6-11
const isIE = !!document.documentMode
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia

makeInitialRequest().then((data) => {
  initI18n(data).then(
    (i18n) => initRouter(i18n).then(
      (i18nAndRouter) => initStore(i18nAndRouter).then(
        (i18nAndRouterAndStore) => {
          const { i18n, router, store } = i18nAndRouterAndStore

          if (hasSavedSessionInfo) {
            store.state.sectors = JSON.parse(sessionStorage.getItem('sectors'))
            store.state.activeSector = JSON.parse(sessionStorage.getItem('activeSector'))
          }

          if (!isIE && !isEdge) {
            const vm = new Vue({
              i18n,
              router,
              store,
              render: h => h(App),
              methods: {
                onBodyScroll() {
                  document.body.classList.remove('modal-open')
                },
                offBodyScroll() {
                  document.body.classList.add('modal-open')
                }
              },
              mounted() {
                const observer = new ResizeObserver(entries => {
                  entries.forEach(entry => {
                    window.parent.postMessage(entry.contentRect.height, '*')
                  })
                })
                observer.observe(document.body)
                window.addEventListener('beforeunload', () => {
                  observer.disconnect()
                })
                document.title = i18n.messages[i18n.locale].message.home.tabTitle ?? 'ТЕХНОНИКОЛЬ | Калькулятор звуковой изоляции'
              }

            }).$mount('#app')
            Vue.directive('click-outside', {
              bind(el, binding) {
                el.addEventListener('click', e => e.stopPropagation())
                document.body.addEventListener('click', binding.value)
              },
              unbind(el, binding) {
                document.body.removeEventListener('click', binding.value)
              }
            })

            return vm
          } else {
            new Vue({
              render: h => h(UnsupportBrowsers),
              methods: {},
              mounted() {}
            }).$mount('#app')
          }
        }
      )
    )
  )
})
